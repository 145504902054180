import api from '../api'
/**
 * 快速查询
 */
export class LabelSecurityCodeApi extends api {
  getSecurityCodeInfoByLabel (labelNum) {
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/getSecurityCodeInfoByLabel/${labelNum}`,
          method: 'get'
        })
      }

    getSecurityCodeNum (data) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getSecurityCodeNum`,
            method: 'post',
            data
        })
    }

    invalid(data) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/invalid`,
            method: 'post',
            data
        })
    }
    recover(invalidRecordId) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/recover/` + invalidRecordId,
            method: 'post'
        })
    }
    getInvalidRecord(data) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/getInvalidRecord`,
            method: 'post',
            data
        })
    }
}

export default new LabelSecurityCodeApi('securityCode')
