<template>
  <div>
    <div style="float: right;margin-top: -100px;">
      <el-form-item>
        <el-button class="filter-item" size="small" type="danger" v-waves @click="securityCodeCancel">防伪码作废</el-button>
        <el-button class="filter-item" size="small" style="margin-left: 10px;" type="info" v-waves @click="cancelRecord">作废历史记录</el-button>
      </el-form-item>
    </div>
    <div>
      <el-row style="margin-left:30%;margin-top:10%">
        <el-form-item>
          <el-input maxlength="24" show-word-limit size="small" style="width: 280px;" placeholder="请输入条形码（SN）"  v-model="labelNum" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item" size="small" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="getSecurityCodeInfo">查询</el-button>
        </el-form-item>
      </el-row>
    </div>
    <el-divider v-if="securityCode" content-position="left">防伪码信息</el-divider><br>
      <el-descriptions  v-if="securityCode" class="margin-top" title="" :column="2" :size="medium" border>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            条码标签
          </template>
          {{securityCode.label}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            条码序号
          </template>
          {{securityCode.applyId}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            制造厂商
          </template>
          {{securityCode.manufacturer}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            制造代码
          </template>
          {{securityCode.manufacturerCode}}
        </el-descriptions-item>
        <el-descriptions-item label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            型号名称
          </template>
          {{securityCode.modelName}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            品类名称
          </template>
          {{securityCode.categoryName}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            品类代码
          </template>
          {{securityCode.categoryCode}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            物料代码
          </template>
          {{securityCode.materialCode}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            设备类型
          </template>
          {{securityCode.type&&securityCode.type==="HOME_APPLIANCES"?'小家电':(securityCode.type==="KITCHEN_APPLIANCE"?'厨电':'')}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            加密结果
          </template>
          {{securityCode.code}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            查询次数
          </template>
          {{securityCode.queryCount}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            首查时间
          </template>
          {{securityCode.firstQueryTime}}
        </el-descriptions-item>
        <el-descriptions-item  width-="100px" label-align="center">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            创建时间
          </template>
          {{securityCode.createTime}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            更新时间
          </template>
          {{securityCode.updateTime}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            是否有效
          </template>
          {{securityCode.valid == true ? '有效' : '失效'}}
        </el-descriptions-item>
        <el-descriptions-item  label-align="center" label-class-name="label" class-name="content">
          <template #label>
            <!-- <i class="el-icon-tickets"></i> -->
            查询链接
          </template>
          {{securityCode.securityCodeLink}}
        </el-descriptions-item>
    </el-descriptions>

<!--    防伪码作废-->
    <el-dialog title="防伪码作废" v-model="cancelVisible" width="40%" :close-on-click-modal="false" center>
      <el-form size="mini" ref="cancelForm" :model="cancelForm" label-width="120px" status-icon>
        <el-form-item label="订单批次" prop="orderBatch" :rules="[{required: true, message: '订单批次不能为空', trigger: 'blur'}]">
          <el-input v-model.trim="cancelForm.orderBatch" maxlength="25" placeholder="请填写订单批次"></el-input>
        </el-form-item>
        <el-form-item label="ODM制造商" prop="manufacturerId" :rules="[{required: true, message: 'ODM制造商必选', trigger: 'blur'}]">
          <el-select filterable style="width: 100%" class="filter-item" placeholder="请选择ODM制造商" v-model="cancelForm.manufacturerId" @change="changeManufacturer('cancel')">
            <el-option v-for="item in manufacturerOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="型号" prop="modelId" :rules="[{required: true, message: '型号必选', trigger: 'blur'}]">
          <el-select filterable style="width: 100%" class="filter-item" placeholder="请选择产品型号" v-model="cancelForm.modelId" @change="changeModel('cancel')">
            <el-option v-for="item in modelOptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-divider style="margin-top: 40px"><p style="font-size: 10px;color: red">如果只作废部分标签，请填写“起始”和“结束”标签</p></el-divider>
        <el-form-item label="起始标签" prop="startLabel">
          <el-input v-model.trim="cancelForm.startLabel" maxlength="50" placeholder="请填写起始标签"></el-input>
        </el-form-item>
        <el-form-item label="结束标签" prop="endLabel">
          <el-input v-model.trim="cancelForm.endLabel" maxlength="50" placeholder="请填写结束标签"></el-input>
        </el-form-item>
      </el-form>
      <template #footer class="dialog-footer">
        <el-button size="mini" @click="cancelVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="handleCancelOrRecover('cancelForm',null)">防伪码作废</el-button>
      </template>
    </el-dialog>
<!--    作废历史-->
    <el-dialog title="作废历史记录" v-model="cancelRecordVisible" width="80%" :close-on-click-modal="false" center>
      <list-condition-template ref="table" :rows="rows" :total="total" v-model:listQuery="listQuery" :footer-show="false">
        <template #condition>
          <el-form-item>
            <el-input placeholder="请填写订单批次" v-model="listQuery.condition.orderBatch" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-select clearable filterable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="请选择ODM制造商" v-model="listQuery.condition.manufacturerId" @change="changeManufacturer('record')">
              <el-option v-for="item in manufacturerOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select clearable filterable style="margin-left: 10px; width: 150px" class="filter-item" placeholder="请选择产品型号" v-model="listQuery.condition.modelId">
              <el-option v-for="item in modelOptions" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
                type="daterange"
                :disabled-date="disabledDate"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="YYYY-MM-DD HH:mm:ss"
                v-model="dateRange"
                @change="selectDate"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
          </el-form-item>
        </template>
        <el-divider></el-divider>
        <template #columns>
          <el-table-column min-width="150px" label="订单批次" property="orderBatch" align="center"/>
          <el-table-column min-width="150px" label="ODM制造商" property="manufacturerName" align="center"/>
          <el-table-column min-width="150px" label="型号" property="modelName" align="center"/>
          <el-table-column min-width="150px" label="起始标签" property="startLabel" align="center"/>
          <el-table-column min-width="150px" label="结束标签" property="endLabel" align="center"/>
          <el-table-column min-width="150px" label="作废数量" property="invalidNum" align="center"/>
          <el-table-column min-width="150px" label="作废时间" property="invalidTime" align="center"/>
          <el-table-column align="center" label="恢复标签" min-width="150px" class-name="fixed-width">
            <template  #default="scope">
              <el-button type="primary" size="mini" @click="handleCancelOrRecover('recoverForm',scope.row)" v-waves>恢复</el-button>
            </template>
          </el-table-column>
        </template>
      </list-condition-template>
    </el-dialog>
<!--    作废、恢复二次确认-->
    <el-dialog :title="title" v-model="cancelOrRecoverVisible" width="30%" :close-on-click-modal="false" center>
      <div>
        <div v-if="cancelRecordFlag === 1">
          <div>
            <div class="el-cancel_count">{{cancelRecordForm.invalidNum}}</div>
            <div class="el-cancel_count__desc">本次作废标签数</div>
            <div class="el-cancel_count__desc2" style="margin-top: 20px">标签作废后将无法在用于生产和使用验证。</div>
            <div class="el-cancel_count__desc2" style="margin-bottom: 20px">请核对并确认作废标签信息！</div>
          </div>
        </div>
        <div v-if="cancelRecordFlag === 2">
          <div>
            <div class="el-cancel_count__desc2">您正在恢复“已作废”的标签。</div>
            <div class="el-cancel_count__desc2" style="margin-bottom: 20px">请核对并确认以下标签信息！</div>
          </div>
        </div>
        <div>
          <el-form class="el-cancel" size="mini" ref="cancelRecordForm" :model="cancelRecordForm" label-width="120px" label-position="left">
            <el-form-item label="订单批次">
              <span>{{cancelRecordForm.orderBatch}}</span>
            </el-form-item>
            <el-form-item label="ODM制造商">
              <span>{{cancelRecordForm.manufacturerName}}</span>
            </el-form-item>
            <el-form-item label="型号">
              <span>{{cancelRecordForm.modelName}}</span>
            </el-form-item>
            <el-form-item label="起始标签">
              <span>{{cancelRecordForm.startLabel}}</span>
            </el-form-item>
            <el-form-item label="结束标签">
              <span>{{cancelRecordForm.endLabel}}</span>
            </el-form-item>
            <el-form-item v-if="cancelRecordFlag === 2" label="标签数量">
              <span>{{cancelRecordForm.invalidNum}}</span>
            </el-form-item>
            <el-form-item v-if="cancelRecordFlag === 2" label="作废时间">
              <span>{{cancelRecordForm.invalidTime}}</span>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="margin-left: 25%">
        <el-button  @click="cancelOrRecoverVisible = false">取消</el-button>
        <el-button :type="cancelRecordFlag === 1 ? 'danger' : 'primary'"  @click="submitCancelOrRecover(cancelRecordFlag === 1 ? 'cancel' : 'recover')">
          {{ cancelRecordFlag === 1 ? "确认作废" : "确认恢复" }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import labelSecurityCodeApi from '@/apis/label/securityCode'
import commonApi from '@/apis/common'
import ListConditionTemplate from "@/components/templates/listConditionTemplate";
export default {
  components: {ListConditionTemplate},
  props: {
    id: {
      type: String
    }
  },
  data() {
    return {
      labelNum: undefined,
      securityCode: undefined,
      cancelVisible: false,
      cancelRecordVisible: false,
      rows: [],
      total: 0,
      listQuery: {
        condition:{
          orderBatch: '',
          manufacturerId: '',
          modelId: '',
          startTime: '',
          endTime: ''
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      },
      allManufacturers: [],
      allModels: '',
      manufacturerOptions: [],
      modelOptions: [],
      cancelForm: {},
      // 防伪码作废/恢复弹窗内容
      title: '',
      cancelRecordFlag: 0,
      cancelOrRecoverVisible: false,
      cancelRecordForm: {},
      dateRange: ''
    }
  },
  created() {
    // this.getCancelRecord()
  },
  mounted(){
  },
  methods:{
    disabledDate(time) {
      const nowDate = new Date();
      // nowDate.setMonth(nowDate.getMonth() - 1)
      return time.getTime() > nowDate.getTime();
    },
    selectDate(data) {
      console.log("selectDate-----------------------------",data)
      if (data != null && data.length === 2) {
        this.listQuery.condition.startTime = data[0]
        const eDate = new Date(data[1])
        // 将时间设置为当天的 23:59:59
        eDate.setHours(23);
        eDate.setMinutes(59);
        eDate.setSeconds(59);
        this.listQuery.condition.endTime = this.dateFormatter(eDate)
      }else {
        this.listQuery.condition.startTime = ''
        this.listQuery.condition.endTime = ''
      }
    },
    /**
     * 防伪码作废
     */
    securityCodeCancel() {
      this.cancelVisible = true
      this.cancelForm = {}
      this.cancelRecordForm = {}
      if (this.allManufacturers.length <= 0 || this.allModels.length <= 0) {
        this.getAllManufacturer()
        this.getAllModels()
      }else {
        this.manufacturerOptions = this.allManufacturers
        this.modelOptions = this.allModels
      }
    },
    getAllManufacturer() {
      commonApi.getAllManufacturers().then(res => {
        this.allManufacturers = res.data
        this.manufacturerOptions = this.allManufacturers
      })
    },
    getAllModels() {
      const condition = {manufacturerId: ''}
      commonApi.getAllModels(condition).then(res => {
        this.allModels = res.data
        this.modelOptions = this.allModels
      })
    },
    changeManufacturer(type) {
      if (type === 'cancel') {
        if (this.cancelForm?.manufacturerId ?? 0) {
          this.cancelForm.modelId = ''
          this.cancelForm.manufacturer = this.allManufacturers.filter(item => item.id === this.cancelForm.manufacturerId)
          this.filterModel(this.cancelForm.manufacturerId)
        }else {
          this.modelOptions = this.allModels
        }
      }
      if (type === 'record') {
        if (this.listQuery.condition?.manufacturerId ?? 0) {
          this.listQuery.condition.modelId = ''
          this.filterModel(this.listQuery.condition.manufacturerId)
        }else {
          this.modelOptions = this.allModels
        }
      }
    },
    changeModel() {
      if (this.cancelForm?.modelId ?? 0) {
        this.cancelForm.model = this.allModels.filter(item => item.id === this.cancelForm.modelId)
      }
    },
    filterModel(manufacturerId) {
      this.modelOptions = this.allModels.filter(item => {
        if (item.manufacturers !== null && item.manufacturers.length > 0) {
          for (const manufacturer of item.manufacturers) {
            if (manufacturer.id === manufacturerId) {
              return item
            }
          }
        }
      })
    },
    /**
     * 作废历史记录
     */
    cancelRecord() {
      this.cancelRecordVisible = true
      this.listQuery.condition = {
        condition:{
          orderBatch: '',
          manufacturerId: '',
          modelId: '',
          startTime: '',
          endTime: ''
        }
      }
      if (this.allManufacturers.length <= 0 || this.allModels.length <= 0) {
        this.getAllManufacturer()
        this.getAllModels()
      }else {
        this.manufacturerOptions = this.allManufacturers
        this.modelOptions = this.allModels
      }
      this.getCancelRecord()
    },
    getCancelRecord() {
      labelSecurityCodeApi.getInvalidRecord(this.listQuery.condition).then(res => {
        this.rows = res.data
      })
    },
    handleFilter() {
      this.getCancelRecord()
    },
    handleCancelOrRecover(form,row) {
      if (form === 'cancelForm') {
        if (!(this.cancelForm?.startLabel ?? 0) ^ !(this.cancelForm?.endLabel ?? 0)) {
          this.$message.warning('起始标签和结束标签必须同时存在')
          return
        }
        this.title = '防伪码作废'
        this.$refs.cancelForm.validate().then(response => {
          if (!response) {
            return
          }
          this.cancelRecordFlag = 1
          this.cancelRecordForm = JSON.parse(JSON.stringify(this.cancelForm))
          this.cancelRecordForm.manufacturerName = this.cancelRecordForm.manufacturer[0].name
          this.cancelRecordForm.modelName = this.cancelRecordForm.model[0].name
          // 获取作废数量
          labelSecurityCodeApi.getSecurityCodeNum(this.cancelForm).then(res => {
            this.cancelRecordForm.startLabel = res.data.startLabel
            this.cancelRecordForm.endLabel = res.data.endLabel
            this.cancelRecordForm.invalidNum = res.data.invalidCount
            this.cancelOrRecoverVisible = true
          }).catch(() => {

          })
        })
      }
      if (form === 'recoverForm') {
        this.title = '标签恢复'
        this.cancelRecordFlag = 2
        this.cancelOrRecoverVisible = true
        this.cancelRecordForm = JSON.parse(JSON.stringify(row))
        console.log("recover--------------------------",row)
      }
    },
    submitCancelOrRecover(type) {
      if (type === 'cancel') {
        // 作废
        labelSecurityCodeApi.invalid(this.cancelRecordForm).then(res => {
          if (res.data) {
            this.$message.success('防伪码作废成功')
            this.cancelVisible = false
            this.cancelOrRecoverVisible = false
          }else {
            this.$message.error('防伪码作废失败')
          }
        }).catch(error => {
          console.log('=============cancel(' + error + ')=================')
        })
      }
      if (type === 'recover') {
        // 恢复
        labelSecurityCodeApi.recover(this.cancelRecordForm.id).then(res => {
          if (res.data) {
            this.$message.success('防伪码标签恢复成功')
            this.cancelOrRecoverVisible = false
            this.getCancelRecord()
          }else {
            this.$message.error('防伪码标签恢复失败')
          }
        }).catch(error => {
          console.log('=============recover(' + error + ')=================')
        })
      }
    },

    getSecurityCodeInfo(){
      const reg = /^[0-9A-Za-z]$/
      if(!this.labelNum){
        this.$message.warning("请输入标签进行查询")
      }else if(this.labelNum.length != 24){
        this.$message.warning("请输入24位标签进行查询")
      }else if(!!reg.test(this.labelNum)){
        this.$message.warning("只能输入数字和字母")
      }else{
        labelSecurityCodeApi.getSecurityCodeInfoByLabel(this.labelNum).then(resp=>{
          this.securityCode = resp.data
          if(!resp.data){
            this.$message.warning("没有查询到此条码")
          }
        })
      }
    },
    dateFormatter(date) {
      date = (date ? new Date(date) : new Date)
      const formatter = 'YYYY-MM-DD HH:mm:ss'
      const Y = date.getFullYear() + '',
          M = date.getMonth() + 1,
          D = date.getDate(),
          H = date.getHours(),
          m = date.getMinutes(),
          s = date.getSeconds()
      return formatter.replace(/YYYY|yyyy/g, Y)
          .replace(/YY|yy/g, Y.substr(2, 2))
          .replace(/MM/g, (M < 10 ? '0' : '') + M)
          .replace(/DD/g, (D < 10 ? '0' : '') + D)
          .replace(/HH|hh/g, (H < 10 ? '0' : '') + H)
          .replace(/mm/g, (m < 10 ? '0' : '') + m)
          .replace(/ss/g, (s < 10 ? '0' : '') + s)
    },
  }
}
</script>

<style >
  .label{
    min-width: 110px;
    word-break: keep-all;
    font-weight:600;
    text-align:center;
  }
  .content{
    width: 50%;
    word-break: break-all;
  }
  .cancelRecover-baseBtn {

  }
  .cancelC {
    background-color: red;
  }
  .recoverC {
    background-color: black;
  }
</style>
<style scoped lang="scss">
.el-cancel {
  background-color: #edeeef;
}
.el-cancel_produce {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
}
.el-cancel_count {
  font-size: 30px;
  color: #c71616;
  font-weight: 600;
  text-align:center;
}
.el-cancel_count__desc {
  margin-top: 10px;
  color: #a7a9a9;
  font-size: 14px;
  font-weight: 600;
  text-align:center;
}
.el-cancel_count__desc2 {
  margin-top: 10px;
  color: #c71616;
  font-size: 14px;
  font-weight: 600;
  text-align:center;
}
</style>
